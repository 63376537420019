import { render, staticRenderFns } from "./SkinsplusItemsCart.vue?vue&type=template&id=6ac2022b&scoped=true&"
import script from "./SkinsplusItemsCart.vue?vue&type=script&lang=ts&"
export * from "./SkinsplusItemsCart.vue?vue&type=script&lang=ts&"
import style0 from "./SkinsplusItemsCart.vue?vue&type=style&index=0&id=6ac2022b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac2022b",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import TouchPan from 'quasar/src/directives/TouchPan.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QBtn,QTooltip});qInstall(component, 'directives', {TouchPan});
