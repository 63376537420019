
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue
} from "vue-property-decorator";
import SkinsplusItem from "@/components/buy/SkinsplusItem.vue";
import { API_ENDPOINT_SYMBOL, USER_PROFILE_SYMBOL } from "@/constants";
import AuthBtn from "@/components/user/AuthBtn.vue";
import gql from "graphql-tag";
import { SkinsplusItemFragment } from "@/graphql/fragments";
import SkinsplusBuyDialog from "@/components/buy/SkinsplusBuyDialog.vue";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";

@Component({
  components: {
    SkinsplusItem,
    AuthBtn,
    SkinsplusBuyDialog,
    Amount,
    CurrencyIcon
  }
})
export default class SkinsplusItemsCart extends Vue {
  @Prop({ default: () => [], type: Array }) selectedSkinsplusItems: any;
  @InjectReactive(USER_PROFILE_SYMBOL) user;
  @Inject(API_ENDPOINT_SYMBOL) public apiEndPoint;
  @Prop({ default: false, type: Boolean }) loading!: boolean;
  successItems: any = [];
  errorItems: any = [];
  createdAt = new Date();

  onClose() {
    this.$emit("refresh");
    this.successItems = [];
    this.errorItems = [];
  }

  async buy() {
    this.$emit("update:loading", true);

    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation buySkinsplusItems($_ids: [String!]!) {
            buySkinsplusItems(_ids: $_ids) {
              ...SkinsplusItem
            }
          }
          ${SkinsplusItemFragment}
        `,
        variables: {
          _ids: this.selectedSkinsplusItems.map(({ _id }) => _id)
        }
      });

      this.createdAt = new Date();
      this.successItems = data.buySkinsplusItems;
      this.errorItems = this.selectedSkinsplusItems.filter(
        ({ _id }) => !this.successItems.find(item => item._id === _id)
      );
    } catch (e) {
      let msg = e.message;

      if (msg.indexOf("no items") !== -1) {
        msg = this.$t("skinsplusItemsCart.empty_store");
      } else {
        msg = `${this.$t(
          "skinsplusItemsCart.error"
        )} ${this['getErrorCodeByMessage'](msg)}`;
      }

      this.$q.notify({
        message: msg,
        multiLine: true,
        type: "negative"
      });
    }
    this.$emit("update:loading", false);
  }

  get sum() {
    return Number(
      this.selectedSkinsplusItems
        .reduce((acc, v) => acc + v.price, 0)
        .toFixed(2)
    );
  }

  get sumBalance() {
    return Number((this.sum - this.sumBonusBalance).toFixed(2));
  }

  get sumBonusBalance() {
    return this.user.isBonusBalanceAllowed && this.user.bonusBalance > 0
      ? Math.min(this.sum, this.user.bonusBalance)
      : 0;
  }

  get count() {
    return this.selectedSkinsplusItems.length;
  }
  deselect(skinsplusItem) {
    if (this.loading) {
      return;
    }
    let updatedArray: any[] = [];
    const selectedIndex = this.selectedSkinsplusItems.findIndex(
      item => item._id === skinsplusItem._id
    );

    updatedArray = [...this.selectedSkinsplusItems];
    updatedArray.splice(selectedIndex, 1);

    this.$emit("update:selectedSkinsplusItems", updatedArray);
  }

  /*handlePan({ delta }) {
    //@ts-ignore
    this.$refs.pan.setScrollPosition(
      //@ts-ignore
      this.$refs.pan.getScrollPosition() - delta.x
    );
  }*/

  handlePan({ delta }) {
    const scrollEl =( this.$refs.pan as any).$el.querySelector(".scroll") as any;
    //@ts-ignore
    scrollEl.scrollLeft = scrollEl.scrollLeft - delta.x;
  }
}
