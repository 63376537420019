import { render, staticRenderFns } from "./SkinsplusItemsList.vue?vue&type=template&id=4ee96fd0&scoped=true&"
import script from "./SkinsplusItemsList.vue?vue&type=script&lang=ts&"
export * from "./SkinsplusItemsList.vue?vue&type=script&lang=ts&"
import style0 from "./SkinsplusItemsList.vue?vue&type=style&index=0&id=4ee96fd0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee96fd0",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QBtn,QScrollArea,QList,QExpansionItem,QCard,QCardSection,QCheckbox});
