
import {
  Component,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import { SkinsplusItemFragment } from "@/graphql/fragments";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import openURL from 'quasar/src/utils/open-url.js';;
import * as _ from "lodash";
import AuthBtn from "@/components/user/AuthBtn.vue";
import { USER_PROFILE_SYMBOL } from "@/constants";
import SkinsplusItem from "@/components/buy/SkinsplusItem.vue";

@Component({
  components: {
    CircleLoading,
    AuthBtn,
    SkinsplusItem
  }
})
export default class SkinsPlusItemsList extends Vue {
  openURL = openURL;
  additionalFilters = true;
  raritiesOptions = [
    {
      value: "High Grade",
      label: this.$t('skinsplusItemsList.rarities.0')
    },
    {
      label: this.$t('skinsplusItemsList.rarities.1'),
      value: "Superior"
    },
    {
      label: this.$t('skinsplusItemsList.rarities.2'),
      value: "Mil-Spec Grade"
    },
    {
      value: "Exotic",
      label: this.$t('skinsplusItemsList.rarities.3'),
    },
    {
      value: "Remarkable",
      label: this.$t('skinsplusItemsList.rarities.4'),
    },
    {
      value: "Contraband",
      label: this.$t('skinsplusItemsList.rarities.5'),
    },
    {
      value: "Classified",
      label: this.$t('skinsplusItemsList.rarities.6'),
    },
    {
      value: "Distinguished",
      label: this.$t('skinsplusItemsList.rarities.7'),
    },
    {
      value: "Consumer Grade",
      label: this.$t('skinsplusItemsList.rarities.8'),
    },
    {
      value: "Extraordinary",
      label: this.$t('skinsplusItemsList.rarities.9'),
    },
    {
      value: "Exceptional",
      label: this.$t('skinsplusItemsList.rarities.10'),
    },
    {
      value: "Master",
      label: this.$t('skinsplusItemsList.rarities.11'),
    },
    {
      value: "Restricted",
      label: this.$t('skinsplusItemsList.rarities.12'),
    },
    {
      value: "Base Grade",
      label: this.$t('skinsplusItemsList.rarities.13'),
    },
    {
      value: "Industrial Grade",
      label: this.$t('skinsplusItemsList.rarities.14'),
    },
    {
      value: "Covert",
      label: this.$t('skinsplusItemsList.rarities.15'),
    }
  ];
  rarities = [];
  exteriorsOptions = [
    {
      value: "Factory New",
      label: this.$t('skinsplusItemsList.exteriors.0')
    },
    {
      value: "Minimal Wear",
      label: this.$t('skinsplusItemsList.exteriors.1')
    },
    {
      value: "Field-Tested",
      label: this.$t('skinsplusItemsList.exteriors.2')
    },
    {
      value: "Well-Worn",
      label: this.$t('skinsplusItemsList.exteriors.3')
    },
    {
      value: "Battle-Scarred",
      label: this.$t('skinsplusItemsList.exteriors.4')
    }
  ];
  exteriors = [];
  tagsOptions = [
    {
      value: "STATTRACK",
      label: this.$t('skinsplusItemsList.tags.0')
    },
    {
      value: "SOUVENIR",
      label: this.$t('skinsplusItemsList.tags.1')
    },
    {
      value: "STICKER",
      label: this.$t('skinsplusItemsList.tags.2')
    }
  ];
  tags = [];
  typesOptions = [
    {
      value: "Gift",
      label: this.$t('skinsplusItemsList.types.0')
    },
    {
      value: "Weapon",
      label: this.$t('skinsplusItemsList.types.1')
    },
    {
      value: "Pass",
      label: this.$t('skinsplusItemsList.types.2')
    },
    {
      value: "Key",
      label: this.$t('skinsplusItemsList.types.3')
    },
    {
      value: "Music Kit",
      label: this.$t('skinsplusItemsList.types.4')
    },
    {
      value: "Container",
      label: this.$t('skinsplusItemsList.types.5')
    },
    {
      value: "Gloves",
      label: this.$t('skinsplusItemsList.types.6')
    },
    {
      value: "Graffiti",
      label: this.$t('skinsplusItemsList.types.7')
    },
    {
      value: "Collectible",
      label: this.$t('skinsplusItemsList.types.8')
    }
  ];
  types = [];
  weaponTypesOptions = [
    {
      value: "SMG",
      label: this.$t('skinsplusItemsList.weaponTypes.0')
    },
    {
      value: "Machinegun",
      label: this.$t('skinsplusItemsList.weaponTypes.1')
    },
    {
      value: "Knife",
      label: this.$t('skinsplusItemsList.weaponTypes.2')
    },
    {
      value: "Sniper Rifle",
      label: this.$t('skinsplusItemsList.weaponTypes.3')
    },
    {
      value: "Rifle",
      label: this.$t('skinsplusItemsList.weaponTypes.4')
    },
    {
      value: "Shotgun",
      label: this.$t('skinsplusItemsList.weaponTypes.5')
    },
    {
      value: "Pistol",
      label: this.$t('skinsplusItemsList.weaponTypes.6')
    }
  ];
  weaponTypes = [];
  searchExpanded = false;
  sortByPrice = "DESC";
  maxSelectedCount = 16;
  public searchText = "";
  public fromPrice: number | null = null;
  public toPrice: number | null = null;
  public fromFloat: number | null = null;
  public toFloat: number | null = null;
  public steamBotId: string | undefined = "";
  public skinsplusItems: any[] = [];
  SkinsplusItemFragment = SkinsplusItemFragment;
  @Prop({ default: () => [], type: Array }) selectedSkinsplusItems: any;
  @Prop({ default: false, type: Boolean }) cartLoading!: boolean;
  @Prop({ default: false, type: Boolean }) skinsplusItemsListRefresh!: boolean;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  loadingMore = false;
  hasMore = false;
  nextPageToken = 1;

  created() {
    this.additionalFilters = !this.$q.platform.is.mobile;
  }

  @Watch("skinsplusItemsListRefresh")
  refresh(value) {
    if (value) {
      this.$emit("update:skinsplusItemsListRefresh", false);
      this.$refs.query["$apollo"].queries.query.refetch();
    }
  }

  resetFilter() {
    this.toPrice = null;
    this.fromPrice = null;
    this.toFloat = null;
    this.fromFloat = null;
    this.exteriors = [];
    this.steamBotId = "";
    this.types = [];
    this.weaponTypes = [];
    this.rarities = [];
    this.tags = [];
  }

  async onScroll(options) {
    if (!this.loadingMore && this.hasMore && options.verticalPercentage > 0.8) {
      this.loadingMore = true;
      await this.loadMore();
      this.loadingMore = false;
    }
  }

  async loadMore() {
    await this.$refs.query["$apollo"].queries.query.fetchMore({
      variables: {
        nextPageToken: this.nextPageToken
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.nextPageToken = fetchMoreResult.getSkinsplusItems.nextPageToken;
        this.hasMore = fetchMoreResult.getSkinsplusItems.hasMore;
        this.skinsplusItems = this.skinsplusItems.concat(
          fetchMoreResult.getSkinsplusItems.skinsplusItems
        );
        return {
          getSkinsplusItems: {
            ...fetchMoreResult.getSkinsplusItems,

            skinsplusItems: [
              ...previousResult.getSkinsplusItems.skinsplusItems,
              ...fetchMoreResult.getSkinsplusItems.skinsplusItems
            ]
          }
        };
      }
    });
  }

  onResult({ data }) {
    if (data) {
      this.skinsplusItems = data.getSkinsplusItems.skinsplusItems;
      this.hasMore = data.getSkinsplusItems.hasMore;
      this.nextPageToken = data.getSkinsplusItems.nextPageToken;
    }
  }

  selectAll() {
    if (this.cartLoading) {
      return;
    }

    let updatedArray: any = [];
    const intersection = this.selectedSkinsplusItems
      .map(item1 => this.skinsplusItems.find(item2 => item1._id === item2._id))
      .filter(n => !!n);

    if (intersection.length === this.skinsplusItems.length) {
      updatedArray = this.selectedSkinsplusItems.filter(
        item1 => !intersection.find(item2 => item1._id === item2._id)
      );
    } else {
      updatedArray = [
        ...this.selectedSkinsplusItems.filter(
          item1 => !this.skinsplusItems.find(item2 => item1._id === item2._id)
        ),
        ...this.skinsplusItems.map(item => _.cloneDeep(item))
      ];
    }

    if (updatedArray.length <= this.maxSelectedCount) {
      this.$emit("update:selectedSkinsplusItems", updatedArray);
    } else {
      this.maxSelectedSteamItemsNotify();
    }
  }

  maxSelectedSteamItemsNotify() {
    this.$q.notify({
      html: true,
      message: this.$t('skinsplusItemsList.max_skins', {maxSelectedCount: this.maxSelectedCount}) as string,
      position: this.$q.platform.is.mobile ? "bottom" : "bottom-left",
      multiLine: true,
      type: "warning"
    });
  }

  select(skinsplusItem) {
    if (this.cartLoading) {
      return;
    }

    let updatedArray: any[] = [];
    const selectedIndex = this.selectedSkinsplusItems.findIndex(
      item => item._id === skinsplusItem._id
    );

    if (selectedIndex === -1) {
      updatedArray = [Object.assign({}, _.cloneDeep(skinsplusItem))].concat(
        this.selectedSkinsplusItems
      );
    } else {
      updatedArray = [...this.selectedSkinsplusItems];
      updatedArray.splice(selectedIndex, 1);
    }

    if (updatedArray.length <= this.maxSelectedCount) {
      this.$emit("update:selectedSkinsplusItems", updatedArray);
    } else {
      this.maxSelectedSteamItemsNotify();
    }
  }
}
