import { render, staticRenderFns } from "./Buy.vue?vue&type=template&id=bccd036c&scoped=true&"
import script from "./Buy.vue?vue&type=script&lang=ts&"
export * from "./Buy.vue?vue&type=script&lang=ts&"
import style0 from "./Buy.vue?vue&type=style&index=0&id=bccd036c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bccd036c",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIntersection,QStepper,QStep,QStepperNavigation,QBtn});
