import { render, staticRenderFns } from "./SkinsplusItem.vue?vue&type=template&id=5ff266e3&scoped=true&"
import script from "./SkinsplusItem.vue?vue&type=script&lang=ts&"
export * from "./SkinsplusItem.vue?vue&type=script&lang=ts&"
import style0 from "./SkinsplusItem.vue?vue&type=style&index=0&id=5ff266e3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff266e3",
  null
  
)

export default component.exports
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIntersection,QImg,QBtn});
