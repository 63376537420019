
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import SkinsplusItemsList from "@/components/buy/SkinsplusItemsList.vue";
import openURL from 'quasar/src/utils/open-url.js';;
import HomeHeading from "@/components/home/HomeHeading.vue";
import TradeUrlInput from "@/components/user/TradeUrlInput.vue";
import SkinsplusItemsCart from "@/components/buy/SkinsplusItemsCart.vue";
import { USER_PROFILE_SYMBOL } from "@/constants";
import AuthBtn from "@/components/user/AuthBtn.vue";

@Component({
  components: {
    SkinsplusItemsList,
    HomeHeading,
    AuthBtn,
    TradeUrlInput,
    SkinsplusItemsCart
  },
  meta() {
    return { title: this.$t("buy.title") };
  }
})
export default class Buy extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) user!: any;
  selectedSkinsplusItems: any[] = [];
  openURL = openURL;
  step = 1;
  cartLoading = false;
  skinsplusItemsListRefresh = false;

  created() {
    if (this.user && this.user.tradeUrl) {
      this.step = 2;
    }
  }

  refresh() {
    this.selectedSkinsplusItems = [];
    this.skinsplusItemsListRefresh = true;
  }
}
