
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import openURL from 'quasar/src/utils/open-url.js';;
import SkinsplusItem from "@/components/buy/SkinsplusItem.vue";

@Component({
  components: {
    SkinsplusItem
  }
})
export default class SkinsplusBuyDialog extends Vue {
  @Prop() successItems: any;
  @Prop() errorItems: any;
  @Prop() createdAt: any;
  show = true;

  @Watch("show")
  close() {
    this.$emit("close");
  }
}
