
import { Component, Prop, Vue } from "vue-property-decorator";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";

@Component({
  components: {
    Amount,
    CurrencyIcon
  }
})
export default class SkinsplusItem extends Vue {
  @Prop() _id: string | undefined;
  @Prop() name: string | undefined;
  @Prop() price: number | undefined;
  @Prop() image: string | undefined;
  @Prop() stickers: [{ name: string; image: string }] | undefined;
  @Prop() steamBotId: string | undefined;
  @Prop() inspectLink: string | null | undefined;
  @Prop() selected: boolean | undefined;
  @Prop() disabled: boolean | undefined;
  @Prop() exterior: string | undefined;
  @Prop() float: number | undefined;
  @Prop({ default: false }) mini: boolean | undefined;

  get quality() {
    return this.exterior ? this.exterior.replace(/[^A-Z]/g, "") : null;
  }

  get stattrack() {
    return this.name?.indexOf("StatTrak™") !== -1;
  }

  get nameWithoutQuality() {
    if (!this.name) {
      return null;
    }

    return this.name.replace(`(${this.exterior})`, "");
  }
}
